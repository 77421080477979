.nav-style {
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-top: 15px;
    padding-bottom: 15px;
    position: absolute;
    z-index: 1;
    width: 100%;
    margin-top: 10px;
}

.ul-style {
    margin-left: auto !important;
}

nav ul a {
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
    padding-left: 0;
    padding-right: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 14px;
    margin-right: 14px;
}

nav ul a:hover {
    color: #fac917 !important
}