#footer {
    /* margin-top: 20px; */
    background-color: #f7fafd;
    padding-left: 130px;

}

ul li a {
    align-items: left;
}

.left-sec p{
    text-align: justify;
}

.col ul li {
    text-align: left;
}

.col ul li:before {
    background-color: #fac917;
}

.col h5 {
    text-align: left;

}

li svg {
    color: #fac917;
}