#aboutus {
    align-items: center;
    padding: 75px 300px;  
    background-color: #f9f6f6;  
}

.h-style {
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 1.4;
    color: #0e314c;
    letter-spacing: 2px !important; 
}

.p-style {
    max-width: 550px !important;
    color: #666666;
    line-height: 1.8;
}