.carousel-item {
    height: 700px !important;
    background-color: #fac917;
    padding: 200px 30px 20px 40px
}

.carousel-control-prev {
    width: 2%;
}

.carousel-control-next {
    width: 2%;
}

.carousel-control-prev-icon {
    background-color: black !important;
    opacity: 1;
    color: white !important;
}

.carousel-control-next-icon {
    background-color: black !important;
    color: white !important;
}

.carousel-control-next, .carousel-control-prev {

    opacity: 1 !important;
    /* transition: opacity .15s ease; */
}
