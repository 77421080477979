#main {
    background-color: #f9f6f6;
    padding-left: 60px;  
    padding-right: 60px;  
}

#main h1 {
    font-size: 25px;
    font-weight: 600;
    color: #0e314c;
    text-align: left;
    letter-spacing: 2px !important;            
}

#main p {
    font-size: 16px;
    color: #666666;
    line-height: 1.8;
    text-align: justify;         
}

.img-style {
    height: auto;
    max-width: 100%;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.main-sec {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 20px;
}

.main-btn {
    font-weight: 600;
    font-size: 14px;
    border: none;
    padding: 15px 40px;
    position: relative;
    border-radius: 4px;
    z-index: 1;
    text-transform: uppercase;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    color: white;
    width: 40%;
}

.btn {
    width: 40%;
    padding: 10px 20px;
    position: relative;
    border:none;
    color: #fff;
    background-color: #222;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    text-transform: uppercase;
    font-family: monospace;
    letter-spacing: -1px;
}
.btn [class^="btn__text"] {
    font-size: 24px;
}
.btn .btn__text-dynamic, .btn .btn__text-dynamic-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    transition: all ease 0.5s;
}
.btn .btn__text-dynamic {
    background-color: #fac917;
    color: #222;
    overflow: hidden;
}
.btn:hover .btn__text-dynamic {
    transform: translateX(-100%);
}
.btn:hover .btn__text-dynamic-inner {
    transform: translateX(100%);
}
.btn--animation-from-right:hover .btn__text-dynamic {
    transform: translateX(100%);
}
.btn--animation-from-right:hover .btn__text-dynamic-inner {
    color: white;
    transform: translateX(-100%);
}