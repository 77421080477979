.heading {
    color: #0e314c;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 130px 70px;
}

.sub-heading {
    color: black;
    font-weight: 600;
    text-align: left;
}

.text-style {
    text-align: left;
    color: #666666;
    font-size: 15px;
}

#privacy-policy h3 {
    text-align: left;
}

#privacy-policy h4 {
    text-align: left;
}

.section {
    padding-top: 10px;
    padding-bottom: 10px;
}


#privacy-policy span {
    font-weight: bold;
    font-size: 15px;
}

.section h6 {
    text-align: left;
    color: #666666;
    font-weight: bold;

}